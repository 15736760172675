import React, { Component } from 'react';
import { db } from '../firebase';
import { Link, Redirect } from 'react-router-dom';
import Dropdown from './global/dropdown';
import { convertRelationshipTextToKey } from '../helpers';
import Modal from './global/modal';
import axios from 'axios';
import AuthUserContext from './authusercontext';
import SendEmailCheck from './sendingCheck';
import { doDeleteTempUserAndCreateUserWithEmailAndPassword } from '../firebase/db';

let userActions = [
  {
    id: 0,
    title: 'View Dashboard',
    selected: false,
    key: 'userAction',
  },
  {
    id: 1,
    title: 'Edit Reviewers',
    selected: false,
    key: 'userAction',
  },
  {
    id: 2,
    title: 'Generate 360 Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 7,
    title: 'Generate Self-Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 3,
    title: 'Move',
    selected: false,
    key: 'userAction',
  },
  {
    id: 5,
    title: 'Remind',
    selected: false,
    key: 'userAction',
  },
  {
    id: 4,
    title: 'Delete User',
    selected: false,
    key: 'userAction',
  },
];

// displays when no emails/instructions have been sent yet. userActions will display once they have
let userActionsPre = [
  {
    id: 0,
    title: 'View Dashboard',
    selected: false,
    key: 'userAction',
  },
  {
    id: 1,
    title: 'Edit Reviewers',
    selected: false,
    key: 'userAction',
  },
  {
    id: 2,
    title: 'Generate 360 Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 7,
    title: 'Generate Self-Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 3,
    title: 'Move',
    selected: false,
    key: 'userAction',
  },
  {
    id: 4,
    title: 'Delete User',
    selected: false,
    key: 'userAction',
  },
];

let userActionsGoalTracker = [
  {
    id: 2,
    title: 'Generate Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 3,
    title: 'Move',
    selected: false,
    key: 'userAction',
  },
  {
    id: 5,
    title: 'Remind',
    selected: false,
    key: 'userAction',
  },
  {
    id: 4,
    title: 'Delete User',
    selected: false,
    key: 'userAction',
  },
];

let userActionsTeamAssessment = [
  {
    id: 1,
    title: 'Edit Reviewers',
    selected: false,
    key: 'userAction',
  },
];

let userActionsComparison = [
  {
    id: 0,
    title: 'View Dashboard',
    selected: false,
    key: 'userAction',
  },
  {
    id: 1,
    title: 'Edit Reviewers',
    selected: false,
    key: 'userAction',
  },
  {
    id: 2,
    title: 'Generate 360 Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 7,
    title: 'Generate Self-Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 6,
    title: 'Comparison Report',
    selected: false,
    key: 'userAction',
  },
  {
    id: 3,
    title: 'Move',
    selected: false,
    key: 'userAction',
  },
  {
    id: 5,
    title: 'Remind',
    selected: false,
    key: 'userAction',
  },
  {
    id: 4,
    title: 'Delete User',
    selected: false,
    key: 'userAction',
  },
];

// {
//   id: 1,
//   title: 'Notify',
//   selected: false,
//   key: 'userAction'
// },
let reviewersByType = {};

class RevieweeDetails extends Component {
  constructor(props) {
    super(props);
    // Empty reviewersByType object

    Object.keys(this.props.current360Relationships).map((key, i) => {
      reviewersByType[key] = {};
    });

    this.state = {
      comparisonModal: false,
      current360Id: this.props.current360Id,
      currentGroupId: this.props.currentGroupId,
      relationships: this.props.current360Relationships,
      questionnaireSections: this.props.questionnaireSections,
      sectionAnswers: null,
      currentUserCompletionStatus: '',
      type: this.props.type,
      loading: true,
      loadedSend: this.props.loadedSend,
      sendingLoading: false,
      currentTaskNo: this.props.currentTaskNo,
      showRemindingUserModal: false,
      showReportModal: false,
      sending: this.props.sending,
      comparisonReportErrors: [],
    };

    this.checkReportActive = this.checkReportActive.bind(this);
    this.checkGoalTrackerReportActive = this.checkGoalTrackerReportActive.bind(this);
    this.getNumCompleted = this.getNumCompleted.bind(this);
    this.fireUserAction = this.fireUserAction.bind(this);
    this.moveUser = this.moveUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.remindUser = this.remindUser.bind(this);
    this.generateReport = this.generateReport.bind(this);
  }
  static contextType = AuthUserContext;

  componentDidMount() {
    // if (this.props.type == '360') {
    if (this.props.type !== 'Goal Tracker') {
      db.doGetSelfReviewCompletionPercentage(
        this.props.accountId,
        this.props.index,
        this.props.current360Id,
        this.props.currentGroupId,
        this.props.questionnaireId,
        'self-review'
      ).then((completionPercentage) => {
        db.doGetAnswers(
          this.props.accountId,
          this.props.index,
          this.props.current360Id,
          this.props.currentGroupId,
          this.props.questionnaireId,
          'self-review'
        ).onSnapshot((snapshot) => {
          let sections = {};
          if (snapshot.exists) {
            snapshot.docs.map((doc, i) => {
              sections[doc.id] = doc.data();
            });
          }

          let userActionsState = [...userActions];
          let userActionsPreState = [...userActionsPre];

          if (completionPercentage !== 100) {
            userActionsPreState = userActionsPreState.filter((action) => action.title !== 'Generate Self-Report' && action.title !== 'Generate 360 Report');
            userActionsState = userActionsState.filter((action) => action.title !== 'Generate Self-Report' && action.title !== 'Generate 360 Report');
          }

          db.doGetCurrentGroupUserReviewers(this.props.accountId, this.props.current360Id, this.props.currentGroupId, this.props.index).onSnapshot(
            (snapshot) => {
              let reviewers = {};
              let allowComparison = false;
              if (snapshot.docs) {
                snapshot.docs.map((doc, i) => {
                  reviewers[doc.id] = doc.data();
                  if (doc.data().completed == true) {
                    allowComparison = true;
                  }
                });
              }

              db.get360details(this.props.accountId, this.state.current360Id).then((res) => {
                const current360Details = res.data();
                let revieweeObj = { revieweeID: this.props.index, completionPercentage: completionPercentage };
                this.props.reviewCompletion(revieweeObj, reviewers);
                if (current360Details.comparisonProject) {
                  db.getPrevious360(this.props.accountId, this.props.index, current360Details.duplicatedFrom)
                    .then((doc) => {
                      let previousGroup = doc.data();
                      db.getPrevious360Completion(
                        this.props.accountId,
                        this.props.index,
                        current360Details.duplicatedFrom,
                        previousGroup.groupID,
                        previousGroup.questionnaireId
                      ).then((res) => {
                        this.setState({
                          currentUserCompletionStatus: completionPercentage === 100 ? 'Complete' : 'Incomplete',
                          previous360CompletionStatus: res,
                          sectionAnswers: sections,
                          reviewers: reviewers,
                          allowComparison: allowComparison,
                          loading: false,
                          userReportLink: `/dashboard/${this.props.accountId}/360s/${this.state.current360Id}/${
                            this.props.type == '360' ? 'report' : 'goal-report'
                          }/${this.props.currentGroupId}/${this.props.index}/`,
                          comparisonReportLink: `/dashboard/${this.props.accountId}/360s/${this.state.current360Id}/comparison/${this.props.currentGroupId}/${this.props.index}/${previousGroup['groupID']}`,
                          userActionsState: userActionsState,
                          userActionsPreState: userActionsPreState,
                        });
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                      this.setState({
                        currentUserCompletionStatus: completionPercentage === 100 ? 'Complete' : 'Incomplete',
                        sectionAnswers: sections,
                        reviewers: reviewers,
                        loading: false,
                        userReportLink: `/dashboard/${this.props.accountId}/360s/${this.state.current360Id}/${
                          this.props.type == '360' ? 'report' : 'goal-report'
                        }/${this.props.currentGroupId}/${this.props.index}/`,
                        userActionsState: userActionsState,
                        userActionsPreState: userActionsPreState,
                      });
                    });
                } else {
                  this.setState({
                    currentUserCompletionStatus: completionPercentage === 100 ? 'Complete' : 'Incomplete',
                    sectionAnswers: sections,
                    reviewers: reviewers,
                    loading: false,
                    userReportLink: `/dashboard/${this.props.accountId}/360s/${this.state.current360Id}/${
                      this.props.type == '360' ? 'report' : 'goal-report'
                    }/${this.props.currentGroupId}/${this.props.index}/`,
                    userActionsState: userActionsState,
                    userActionsPreState: userActionsPreState,
                  });
                }
              });
            }
          );
        });
      });
      // console.log('currentUserCompletionStatus', this.state.currentUserCompletionStatus);
    } else {
      if (this.state.questionnaireSections) {
        let sections = {};
        Object.keys(this.state.questionnaireSections).map((sectionKey, i) => {
          db.doGetGoalStatuses(
            this.props.accountId,
            this.props.index,
            this.props.current360Id,
            this.props.currentGroupId,
            this.props.questionnaireId,
            'self-review',
            sectionKey
          ).onSnapshot((snapshot) => {
            if (snapshot.exists) {
              sections[sectionKey] = snapshot.data();
            }

            if (Object.keys(this.state.questionnaireSections).length - 1 === i) {
              this.setState({
                sectionAnswers: sections,
                loading: false,
                userReportLink: `/dashboard/${this.props.accountId}/360s/${this.state.current360Id}/${this.props.type == '360' ? 'report' : 'goal-report'}/${
                  this.props.currentGroupId
                }/${this.props.index}/`,
              });
            }
          });
        });
      } else {
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        loadedSend: this.props.loadedSend,
      });
    }
  }

  moveUser() {
    this.props.openMovingUserModal(this.props.index);
  }

  deleteUser() {
    this.props.openDeletingUserModal(this.props.index);
  }

  remindUser() {
    // this.props.openRemindingUserModal(this.props.index);
    this.setState({
      showRemindingUserModal: true,
    });
  }

  viewUser() {
    // opens user dashboard in new window:
    const win = window.open(
      `${process.env.REACT_APP_SELF_REVIEW_DOMAIN}/${this.props.accountId}/${this.props.index}/360/${this.state.current360Id}/${this.state.currentGroupId}/`,
      '_blank'
    );
    if (win != null) {
      win.focus();
    }
  }

  editUser() {
    this.props.editUser(this.props.index);
  }

  generateReport(userSelfReportLink) {
    if (this.checkGoalTrackerReportActive() || this.checkReportActive()) {
      this.props.redirectToReport(userSelfReportLink ? userSelfReportLink : this.state.userReportLink);
    } else {
      alert('Cannot generate report - Reviews incomplete');
    }
  }

  checkReportActive() {
    if (!this.props.groupUserDetails.completed) {
      return false;
    } else {
      return true;

      // let numberReviewerReviewsComplete = 0
      //
      // Object
      //   .keys(this.props.current360Relationships)
      //   .map((key, i) => {
      //     if(this.getNumCompleted(key) > 0) {
      //       numberReviewerReviewsComplete++
      //     }
      //   })
      //
      // if(numberReviewerReviewsComplete > 0) {
      //   return true
      // } else {
      //   return false
      // }
    }
  }

  checkGoalTrackerReportActive() {
    if (!this.state.sectionAnswers) {
      return false;
    } else {
      return true;
    }
  }

  getNumCompleted(type) {
    let numCompleted = 0;
    if (this.props.type !== 'Team Assessment') {
      Object.keys(reviewersByType[type]).map((key) => {
        if (reviewersByType[type][key].completed) {
          numCompleted++;
        }
      });
    } else {
      for (const reviewer in this.state.reviewers) {
        if (this.state.reviewers[reviewer].completed === true) {
          numCompleted++;
        }
      }
    }

    return numCompleted;
  }

  sendSelfReminderEmail(e) {
    this.setState({ sendingLoading: 'Generating Email' });

    const reqBody = {
      accountId: this.props.accountId,
      userEmail: this.props.accountData.email,
      current360Id: this.state.current360Id,
      currentTaskNo: this.state.currentTaskNo,
      currentGroupId: this.props.currentGroupId,
      current360Title: this.props.current360.title,
      questionnaireId: this.props.current360.questionnaireID,
      email: this.props.groupUserDetails.email,
      forename: this.props.groupUserDetails.forename,
      surname: this.props.groupUserDetails.surname,
      logo: this.props.accountData.logo,
      uid: this.props.index,
    };
    if (this.props.type === '360') {
      axios
        .post('/sendSelfReviewReminder', {
          reqBody,
          cookies: window.localStorage.getItem('Auth'),
        })
        .then((res) => {
          this.setState({ sendingLoading: 'Email now in transit' });
        })
        .catch((err) => {
          this.setState({ sendingLoading: 'There has been an error generating this reminder' });
        });
    } else {
      axios
        .post('/sendGoalReminder', {
          reqBody,
          cookies: window.localStorage.getItem('Auth'),
        })
        .then((res) => {
          this.setState({ sendingLoading: 'Email now in transit' });
        })
        .catch((err) => {
          this.setState({ sendingLoading: err });
        });
    }
  }

  comparisonReport() {
    //check for current 360 completion by the reviewee & reviewers
    //check for previous 360 reviewee completion & that they have a reviewer
    let errors = [];
    if (this.state.previous360CompletionStatus.reviewers <= 0) {
      errors.push('There needs to be at least one completed reviewer on their previous 360');
    }
    if (this.state.previous360CompletionStatus.userCompletion.completionPercentage !== 100) {
      errors.push('The self-review on the previous 360 needs to have been completed');
    }
    if (!this.state.allowComparison) {
      errors.push('There are no completed reviews for this 360');
    }
    if (this.state.currentUserCompletionStatus === 'Incomplete') {
      errors.push('The self-review for this 360 is not complete');
    }

    if (errors.length === 0) {
      this.setState({
        showComparisonOption: true,
        showReportModal: true,
      });
    } else {
      this.setState({
        comparisonReportErrors: errors,
        comparisonModal: true,
      });
    }
  }

  fireUserAction(id, key) {
    let temp = JSON.parse(JSON.stringify(userActionsComparison));
    temp.forEach((item) => (item.selected = false));
    temp[id].selected = true;

    switch (id) {
      case 0:
        this.viewUser();
        break;
      case 1:
        this.editUser();
        break;
      case 2:
        //check if this is a 360 or GT
        if (this.props.current360.type === '360') {
          let reviewerCompleted;
          //check to see if any of the reviewers have completed their review
          //if they have then se this to true
          Object.keys(this.state.reviewers).map((id, i) => {
            if (this.state.reviewers[id].completed === true) {
              reviewerCompleted = true;
            }
          });
          //if at least 1 reviewer & the reviewee have completed their review then allow the report to generate
          //if not, throw the 'unable to generate' modal
          if (reviewerCompleted && this.state.currentUserCompletionStatus === 'Complete') {
            this.setState({ showReportModal: true });
          } else {
            let errors = [];
            if (this.state.currentUserCompletionStatus !== 'Complete') {
              errors.push('The self-review for this 360 is not complete');
            }
            //check if there are any reviewers
            if (!reviewerCompleted) {
              errors.push('There needs to be a minimum of 1 completed review to generate a report');
            }

            this.setState({
              comparisonModal: true,
              comparisonReportErrors: errors,
            });
          }
        } else {
          this.generateReport();
        }
        break;
      case 3:
        this.moveUser();
        break;
      case 4:
        this.deleteUser();
        break;
      case 5:
        this.remindUser();
        break;
      case 6:
        this.comparisonReport();
        break;
      case 7:
        const reportURL = this.state.userReportLink + 'self-report/';
        //check if this is a 360 or GT
        if (this.props.current360.type === '360') {
          //if at least 1 reviewee has completed their review then allow the report to generate
          //if not, throw the 'unable to generate' modal
          if (this.state.currentUserCompletionStatus === 'Complete') {
            // this.setState({ showReportModal: true, userReportLink: reportURL });
            this.setState({ userReportLink: reportURL });
            // console.log('userReportLink', this.state.userReportLink);
            this.generateReport(reportURL);
          } else {
            let errors = [];
            if (this.state.currentUserCompletionStatus !== 'Complete') {
              errors.push('The self-review for this 360 is not complete');
            }
            this.setState({
              comparisonModal: true,
              comparisonReportErrors: errors,
            });
          }
        } else {
          this.generateReport();
        }
        break;
      default:
        return;
    }
  }

  render() {
    if (this.props.type == '360') {
      this.props.orderedRelationshipKeys.map((key, i) => {
        reviewersByType[key] = {};
      });
    }

    return this.props.current360.createdDate <= 1632543899869 ? (
      <div className='flex mv2 items-center'>
        {this.props.type == '360' ? (
          <div className='w-30 tc'>
            <p className='has-tooltip' data-tooltip={this.props.groupUserDetails.email}>
              <strong>
                {this.props.groupUserDetails.forename} {this.props.groupUserDetails.surname}
              </strong>
              <br />
              Self-review:{' '}
              <strong className={this.state.currentUserCompletionStatus == 'Complete' ? 'green' : 'red'}>{this.state.currentUserCompletionStatus}</strong>
            </p>
          </div>
        ) : (
          <div className={this.props.type === '360' ? 'w-30 tc ' : 'w-20 tc '}>
            <p className='reset-mar has-tooltip' data-tooltip={this.props.groupUserDetails.email}>
              <strong>
                {this.props.groupUserDetails.forename} {this.props.groupUserDetails.surname}
              </strong>
            </p>
          </div>
        )}
        {this.props.type == '360' ? (
          <div className='w-30 tc'>
            <p className='flex reset-mar'>
              {!this.state.loading
                ? this.state.reviewers
                  ? Object.keys(this.state.reviewers).map((key) => {
                      if (this.state.reviewers[key].relationship) {
                        let convertedRelationshipKey;

                        if (reviewersByType[this.state.reviewers[key].relationship]) {
                          convertedRelationshipKey = this.state.reviewers[key].relationship;
                        } else {
                          convertedRelationshipKey = convertRelationshipTextToKey(this.state.reviewers[key].relationship);
                        }

                        reviewersByType[convertedRelationshipKey][key] = {
                          completed: this.state.reviewers[key].completed,
                        };
                      }
                    })
                  : ''
                : ''}
              {Object.keys(this.props.current360Relationships).map((key, i) => {
                return (
                  <strong key={key} className='w-25 br b--moon-gray'>
                    {Object.keys(reviewersByType[key]).length}/{this.getNumCompleted(key)}
                  </strong>
                );
              })}
              {/*
                  <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["DR"]).length}/{ this.getNumCompleted("DR") }</strong>
                  <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["P"]).length}/{ this.getNumCompleted("P") }</strong>
                  <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["O"]).length}/{ this.getNumCompleted("O") }</strong>
                  <strong className="w-25">{Object.keys(reviewersByType["LM"]).length}/{ this.getNumCompleted("LM") }</strong>
                  */}
            </p>
          </div>
        ) : (
          <div className={this.props.type == '360' ? 'w-40 tc' : 'w-30 tc'}>
            <p className='flex reset-mar'>
              {!this.state.loading ? (
                Object.keys(this.state.questionnaireSections).map((key, i) => {
                  return (
                    <strong key={key} className={i == 0 ? `w-100 br b--moon-gray bl` : 'w-100 br b--moon-gray'}>
                      <i
                        className={
                          this.state.sectionAnswers && this.state.sectionAnswers[key] && this.state.sectionAnswers[key]['goalComplete']
                            ? 'goal-status goal-status--complete'
                            : 'goal-status'
                        }
                      />
                    </strong>
                  );
                })
              ) : (
                <strong className='w-100'>Loading status</strong>
              )}
            </p>
          </div>
        )}

        {this.props.type == '360' ? (
          <div className='w-10 tc'>
            <p className='reset-mar'> - </p>
          </div>
        ) : (
          ''
        )}
        {!this.state.loading ? (
          this.props.type == '360' ? (
            this.state.currentTaskNo > 1 ? (
              this.state.comparisonReportLink ? (
                <div className='w-30'>
                  <Dropdown title='User actions' list={userActionsComparison} setTitleOnSelect={false} resetThenSet={this.fireUserAction} />
                </div>
              ) : (
                <div className='w-30'>
                  <Dropdown
                    title='User actions'
                    list={this.state.userActionsState ? this.state.userActionsState : ['loading...']}
                    setTitleOnSelect={false}
                    resetThenSet={this.fireUserAction}
                  />
                </div>
              )
            ) : (
              <div className='w-30'>
                <Dropdown
                  title='User actions'
                  list={this.state.userActionsPreState ? this.state.userActionsPreState : ['loading...']}
                  setTitleOnSelect={false}
                  resetThenSet={this.fireUserAction}
                />
              </div>
            )
          ) : (
            <div className='w-30'>
              <Dropdown title='User actions' list={userActionsGoalTracker} setTitleOnSelect={false} resetThenSet={this.fireUserAction} />
            </div>
          )
        ) : (
          ''
        )}

        <Modal
          isOpen={this.state.showReportModal}
          contentLabel='Generate Report'
          onClose={() =>
            this.setState({
              showReportModal: false,
            })
          }
          hideCloseButton={false}
          content={
            <div>
              <h2 className='tc'>Please Note</h2>
              <p>
                Before you generate this report, please ensure that the anonymity of the feedback is maintained. Where possible, try to ensure that no
                individual's feedback is exposed when you share the report with the end-user. Please note, this may not always be possible, particularly with
                Line Manager feedback.
              </p>
              <div className='flex justify-between'>
                <button onClick={(e) => this.generateReport()} className='btn'>
                  Proceed
                </button>
                <button
                  className='btn btn--red'
                  onClick={(e) => {
                    this.setState({ showReportModal: false });
                  }}
                >
                  Review
                </button>
              </div>
            </div>
          }
        />

        <Modal
          isOpen={this.state.showRemindingUserModal}
          contentLabel='Reminding User'
          onClose={() =>
            this.setState({
              showRemindingUserModal: false,
              individualReminderEmailSent: false,
              sendingLoading: false,
            })
          }
          hideCloseButton={this.state.sendingLoading === 'Generating Email'}
          content={
            this.state.sendingLoading === false ? (
              <div>
                <h2>This will send the selected user a notification email to complete their {this.props.type}.</h2>
                <p>Click confirm below to send email</p>

                <button onClick={(e) => this.sendSelfReminderEmail(e)} className='btn'>
                  Confirm
                </button>
              </div>
            ) : (
              <div className='w-100'>
                <div className='center tc'>{this.state.sendingLoading}</div>
                <button className='btn' onClick={this.setState({ showRemindingUserModal: false, individualReminderEmailSent: false, sendingLoading: false })}>
                  Close
                </button>
              </div>
            )
          }
        />
      </div>
    ) : (
      <div className='flex mv2 items-center'>
        {this.props.type !== 'Goal Tracker' ? (
          <div className='w-30 tc'>
            <p className='has-tooltip' data-tooltip={this.props.groupUserDetails.email}>
              {this.props.type === '360' ? (
                <>
                  <strong>{`${this.props.groupUserDetails.forename} ${this.props.groupUserDetails.surname}`}</strong>
                  <br />
                  Self-review:{' '}
                  <strong className={this.state.currentUserCompletionStatus == 'Complete' ? 'green' : 'red'}>{this.state.currentUserCompletionStatus}</strong>
                </>
              ) : (
                <strong>{this.props.groupUserDetails.name}</strong>
              )}
            </p>
          </div>
        ) : (
          <div className={this.props.type === '360' ? 'w-30 tc ' : 'w-20 tc '}>
            <p className='reset-mar has-tooltip' data-tooltip={this.props.groupUserDetails.email}>
              <strong>
                {this.props.groupUserDetails.forename} {this.props.groupUserDetails.surname}
              </strong>
            </p>
          </div>
        )}
        <div className={this.props.type === '360' ? 'w-20 tc ' : 'w-20 tc '}>
          <SendEmailCheck
            loadedSend={this.state.loadedSend}
            currentTaskNo={this.state.currentTaskNo}
            reviewers={this.state.reviewers}
            groupUserDetails={this.props.groupUserDetails}
            sending={this.props.sending}
          />
        </div>
        {this.props.type == '360' ? (
          <div className='w-30 tc'>
            <p className='flex reset-mar'>
              {!this.state.loading
                ? this.state.reviewers
                  ? Object.keys(this.state.reviewers).map((key) => {
                      if (this.state.reviewers[key].relationship) {
                        let convertedRelationshipKey;

                        if (reviewersByType[this.state.reviewers[key].relationship]) {
                          convertedRelationshipKey = this.state.reviewers[key].relationship;
                        } else {
                          convertedRelationshipKey = convertRelationshipTextToKey(this.state.reviewers[key].relationship);
                        }

                        if (this.props.type === '360')
                          reviewersByType[convertedRelationshipKey][key] = {
                            completed: this.state.reviewers[key].completed,
                          };
                      }
                    })
                  : ''
                : ''}
              {this.props.type !== 'Team Assessment' ? (
                this.props.orderedRelationshipKeys.map((key, i) => {
                  return (
                    <strong key={key} className='w-25 br b--moon-gray'>
                      {Object.keys(reviewersByType[key]).length}/{this.getNumCompleted(key)}
                    </strong>
                  );
                })
              ) : (
                <strong className='w-100'>{`${this.state.reviewers ? Object.keys(this.state.reviewers).length : ''}/${this.getNumCompleted()}`}</strong>
              )}
            </p>
          </div>
        ) : (
          <div className={this.props.type == '360' ? 'w-40 tc' : 'w-30 tc'}>
            <p className='flex reset-mar'>
              {!this.state.loading ? (
                Object.keys(this.state.questionnaireSections).map((key, i) => {
                  return (
                    <strong key={key} className={i == 0 ? `w-100 br b--moon-gray bl` : 'w-100 br b--moon-gray'}>
                      <i
                        className={
                          this.state.sectionAnswers && this.state.sectionAnswers[key] && this.state.sectionAnswers[key]['goalComplete']
                            ? 'goal-status goal-status--complete'
                            : 'goal-status'
                        }
                      />
                    </strong>
                  );
                })
              ) : (
                <strong className='w-100'>Loading status</strong>
              )}
            </p>
          </div>
        )}

        {this.props.type == '360' ? (
          <div className='w-10 tc'>
            <p className='reset-mar'> - </p>
          </div>
        ) : (
          ''
        )}
        {!this.state.loading ? (
          this.props.type === '360' ? (
            this.state.currentTaskNo > 1 ? (
              this.state.comparisonReportLink ? (
                <div className='w-30'>
                  <Dropdown title='User actions' list={userActionsComparison} setTitleOnSelect={false} resetThenSet={this.fireUserAction} />
                </div>
              ) : (
                <div className='w-30'>
                  <Dropdown
                    title='User actions'
                    list={this.state.userActionsState ? this.state.userActionsState : ['loading...']}
                    setTitleOnSelect={false}
                    resetThenSet={this.fireUserAction}
                  />
                </div>
              )
            ) : (
              <div className='w-30'>
                <Dropdown
                  title='User actions'
                  list={this.state.userActionsPreState ? this.state.userActionsPreState : ['loading...']}
                  setTitleOnSelect={false}
                  resetThenSet={this.fireUserAction}
                />
              </div>
            )
          ) : this.props.type === 'Goal Tracker' ? (
            <div className='w-30'>
              <Dropdown title='User actions' list={userActionsGoalTracker} setTitleOnSelect={false} resetThenSet={this.fireUserAction} />
            </div>
          ) : (
            <div className='w-40 ph5'>
              <Dropdown title='User actions' list={userActionsTeamAssessment} setTitleOnSelect={false} resetThenSet={this.fireUserAction} />
            </div>
          )
        ) : (
          ''
        )}

        <Modal
          isOpen={this.state.showRemindingUserModal}
          contentLabel='Reminding User'
          onClose={() =>
            this.setState({
              showRemindingUserModal: false,
              individualReminderEmailSent: false,
              sendingLoading: false,
            })
          }
          hideCloseButton={this.state.sendingLoading === 'Generating Email'}
          content={
            this.state.sendingLoading === false ? (
              <div>
                <h2>This will send the selected user a notification email to complete their {this.props.type}.</h2>
                <p>Click confirm below to send email</p>

                <button onClick={(e) => this.sendSelfReminderEmail(e)} className='btn'>
                  Confirm
                </button>
              </div>
            ) : (
              <div className='w-100 tc'>
                <div className='center tc'>{this.state.sendingLoading}</div>
                <button
                  className='btn mt3'
                  onClick={(e) => this.setState({ showRemindingUserModal: false, individualReminderEmailSent: false, sendingLoading: false })}
                >
                  Close
                </button>
              </div>
            )
          }
        />

        <Modal
          isOpen={this.state.showReportModal}
          contentLabel='Generate Report'
          onClose={() =>
            this.setState({
              showReportModal: false,
              showComparisonOption: false,
            })
          }
          hideCloseButton={false}
          content={
            <div>
              <h2 className='tc'>Please Note</h2>
              <p>
                Before you generate this report, please ensure that the anonymity of the feedback is maintained. Where possible, try to ensure that no
                individual's feedback is exposed when you share the report with the end-user. Please note, this may not always be possible, particularly with
                Line Manager feedback.
              </p>
              {this.state.showComparisonOption ? (
                <p>
                  <strong>Please ensure that feedback has been completed on the previous 360, before running this report.</strong>
                </p>
              ) : (
                ''
              )}
              {this.state.showComparisonOption ? (
                <div className='flex justify-between'>
                  <button onClick={(e) => this.props.redirectToReport(this.state.comparisonReportLink)} className='btn'>
                    Proceed
                  </button>
                  <button
                    className='btn btn--red'
                    onClick={(e) => {
                      this.setState({ showReportModal: false, showComparisonOption: false });
                    }}
                  >
                    Review
                  </button>
                </div>
              ) : (
                <div className='flex justify-between'>
                  <button onClick={(e) => this.generateReport()} className='btn'>
                    Proceed
                  </button>
                  <button
                    className='btn btn--red'
                    onClick={(e) => {
                      this.setState({ showReportModal: false, showComparisonOption: false });
                    }}
                  >
                    Review
                  </button>
                </div>
              )}
            </div>
          }
        />
        <Modal
          isOpen={this.state.comparisonModal}
          contentLabel='Insufficient Data for Comparison Report'
          onClose={() =>
            this.setState({
              comparisonModal: false,
            })
          }
          hideCloseButton={false}
          content={
            <div>
              <h2 className='tc'>Please Note</h2>
              <p>The report has not been able to generate for the following reason(s):</p>
              <ul>
                {this.state.comparisonReportErrors.map((reason) => {
                  return <li>{reason}</li>;
                })}
              </ul>
              <div className='w-100 tc'>
                <button
                  className='btn btn--red'
                  onClick={(e) => {
                    this.setState({ comparisonModal: false });
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default RevieweeDetails;
